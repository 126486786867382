import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Network from "./components/network"
import Partner from "./components/partner"
import test from "@assets/profil/everything_DiSC_certification.jpg"

// components
import Profil from "./components/profil"
import Slide from "./components/slide"

// styles
import * as Styled from "./Team.styles"

const query = graphql`
  query {
    christine: file(relativePath: { regex: "/christine.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    sabine: file(relativePath: { eq: "profil/sabine.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    anette_kuhr: file(relativePath: { regex: "/anette_kuhr.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    achim_stark: file(relativePath: { regex: "/achim_stark.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    thomas_lehner: file(relativePath: { regex: "/thomas_lehner.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    christine_lehner_kommentar: file(
      relativePath: { regex: "/christine_lehner_kommentare.jpg$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    sabine_weihe_kommentar: file(
      relativePath: { regex: "/sabine_weihe_kommentare.jpg$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    heiko_thurner: file(relativePath: { regex: "/heiko_thurner.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    kaja_novac: file(relativePath: { regex: "/kaja_novac.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    ingrid_wild: file(relativePath: { regex: "/ingrid_wild.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    marianne_frye: file(relativePath: { regex: "/marianne_frye.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    maria_tiefenthaler: file(
      relativePath: { regex: "/maria_tiefenthaler.jpg$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    logo_hattinger_buero: file(
      relativePath: { regex: "/logo_hattinger_buero.jpg$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    logo_sysTelios_transfer: file(
      relativePath: { regex: "/logo_sysTelios_transfer.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
  }
`

const Team = () => {
  const {
    christine,
    sabine,
    anette_kuhr,
    christine_lehner_kommentar,
    sabine_weihe_kommentar,
    heiko_thurner,
    thomas_lehner,
    kaja_novac,
    achim_stark,
    ingrid_wild,
    marianne_frye,
    maria_tiefenthaler,
    logo_hattinger_buero,
    logo_sysTelios_transfer,
  } = useStaticQuery(query)

  const christine_lehner: any = getImage(christine_lehner_kommentar)
  const sabine_weiher: any = getImage(sabine_weihe_kommentar)

  const photos = [
    {
      src: test,
      width: 1920,
      height: 720,
    },
  ]

  return (
    <Styled.Container>
      <Styled.Intro>
        <h1>Über Uns & Unser Netzwerk</h1>
        <h3>
          Unser Ziel ist es, die Denk- und Handlungsmuster von Menschen und
          Organisationen bewusst zu machen und gemeinsam zu reflektieren.
        </h3>
        <Profil
          img={christine}
          imgAlt="test"
          name="Christine Lehner"
          job="Trainerin, Beraterin, Coach"
          p={[
            "Geschäftsführerin Ammerthaler Institut für Organisations- und Personalentwicklung; seit 1992 Beratung, Training, Coaching für internationale Konzerne und Mittelstandsunternehmen",
            "Diplom-Pädagogin Univ., Organisationsberatung und Coaching (MEI Heidelberg), Psychotherapeutin HPG",
            "Selbst- und Stressmanagement, Führungskräfteentwicklung, mentales Training, agiler führen sowie die Begleitung komplexer Veränderungsprozesse",
            "Wie können Menschen in agilen Zeiten innerlich Halt finden? Wie verändern sich Menschen und Organisationen nachhaltig? Welche Bedingungen ermöglichen zieldienliche und motivierende Kooperation?",
          ]}
        />
      </Styled.Intro>
      <Styled.Details>
        <div className="background"></div>
        <Slide
          data={[
            {
              text: (
                <p>
                  So ist die OE fast 10 Jahre nach dem Beginn im Haus immer noch
                  fest verankert. Das Wachstum des Heilpädagogischen Zentrums in
                  diesem Zeitraum wäre ohne die im OE-Prozess entwickelten
                  Strukturen nicht denk- und umsetzbar gewesen. Visionen sind
                  Wirklichkeit geworden. Wir sind dem Ammerthaler Institut durch
                  viele weitere Veranstaltungen wie Teamentwicklungsseminare,
                  Moderationsfortbildungen und Supervision dauerhaft verbunden
                  geblieben.
                </p>
              ),
              author: "Isabel Wernekke",
              job: "Sonderschulrektorin und Leiterin des Heilpäd. Zentrums Amberg",
              blockquoteActive: true,
              blockquote:
                "Frau Lehner und Frau Frye beeindruckten uns nicht nur durch ihre fachliche Expertise, sondern auch durch die Authentizität, mit der sie diese lebten.",
            },
            {
              text: (
                <p>
                  Frau Christine Lehner hat zusammen mit weiteren Trainern für
                  das Jobcenter Dachau im Herbst 2018 ein Teamwochenende
                  durchgeführt. Bei der Planung ist sie auf die Wünsche und
                  Bedarfe sehr gut eingegangen.
                  <br /> Resilienz ist ein wichtiger Baustein im Rahmen einer
                  gesundheitsorientieren Mitarbeiterführung. In ihrem Workshop
                  gab sie einen Einblick in mentale Techniken, die dabei
                  unterstützen, auch in unsicheren Situationen souverän zu
                  bleiben. Die Trainerin hat die Teilnehmer zur motivierten
                  Mitarbeit angeregt und erreicht, dass sich Jeder seiner
                  individuellen inneren Stärken bewusst wird und im Bedarf
                  einsetzen kann.
                </p>
              ),
              author: "Peter Schadl",
              job: "Geschäftsführer Jobcenter Dachau",
              blockquoteActive: true,
              blockquote:
                "Ich kann Christine Lehner speziell für diese Themengebiete sehr als professionelle Beraterin/ Trainerin sehr empfehlen.",
            },
            {
              text: (
                <p>
                  Auf der Grundlage eines Vortrags zum Thema "Agile Führung"
                  konnte Frau Lehner im Kreis der Abteilungsleitungen wichtige
                  Anregungen zur Diskussion eines modernen
                  Führungsverständnisses geben. Dabei beeindruckte ihre
                  souveräne, auf einem breit gefächerten Wissen und aktuellen
                  Erkenntnissen beruhende Vortragsweise. Wir haben inspirierende
                  Impuse von ihr bekommen.
                </p>
              ),
              author: "Dr. Karin E. Oechslein",
              job: "Direktorin Staatsinstitut für Schulqualität und Bildungsforschung, München",
              blockquoteActive: false,
            },
            {
              text: "Christine Lehner`s approach and experienced was effective in improving dynamic and collaboration in our team. A very positive outcome.",
              author: "Ian M. Bolin",
              job: "Sales Manager, South Africa, Audi AG Ingolstadt",
              blockquoteActive: false,
            },
            {
              text: (
                <p>
                  Mit ihrer Besonnenheit und ihrem langjährigen Erfahrungsschatz
                  bei gleichzeitiger Offenheit und Flexibilität ist sie eine
                  große Bereicherung für unser Seminarprogramm, aktuell im
                  Bereich Mentorenqualifizierung/ Reverse Mentoring.
                </p>
              ),
              author: "Oliver Schadow",
              job: "AUDI AG, Fuehrungs-/ Managementprogramme",
              blockquoteActive: true,
              blockquote:
                "Ich habe Christine Lehner als sehr erfahrene, kompetente und verbindliche Trainerin kennengelernt.",
            },
            {
              text: (
                <div>
                  <p>
                    Seit 2006 bis heute arbeitet die Hochschule Rosenheim in der
                    berufsbegleitenden Weiterbildung sehr erfolgreich mit Sabine
                    Weihe und Christine Lehner als Dozentinnen zusammen. Wir
                    schätzen die professionelle und sehr angenehme
                    Zusammenarbeit mit dem Ammerthaler Institut. Nachfolgend
                    einige der vielen positiven Beispiele der Beurteilung
                    unserer Teilnehmer:
                  </p>
                  <ul
                    style={{ listStyle: "disc", marginLeft: "var(--gap-13)" }}
                  >
                    <li
                      style={{
                        marginBottom: "var(--gap-13)",
                        marginTop: "var(--gap-13)",
                      }}
                    >
                      „Verbindung wissenschaftlich fundierte Ansätze mit
                      langjähriger praxisorientierter Erfahrung aus der
                      Wirtschaft“ und „sehr erfahrungsorientiertes Lernen mit
                      Spaß…“
                    </li>
                    <li style={{ marginBottom: "var(--gap-13)" }}>
                      „Sehr gut waren Aufbau und Inhalt des Seminars; Theorie
                      praktische Übungen; Teamwork, Eingehen auf situative
                      Fragen und die Gestaltung des Seminars…“
                    </li>
                    <li>
                      „Die exzellente Mischung zwischen Theorie und Praxis
                      (Gruppenarbeiten, Rollenspiele) hat mir besonders gut
                      gefallen...“
                    </li>
                  </ul>
                </div>
              ),
              author: "Prof. Bäßler",
              job: "FH Rosenheim",
              blockquoteActive: false,
            },
            {
              text: (
                <p>
                  Christine Lehner vom Hattinger Büro hat im Rahmen unseres
                  Management-Entwicklungs-Programms (MEP) diverse Trainings aus
                  dem Programmteil „Personalführung“ konzipiert und
                  durchgeführt. Das Trainingskonzept basiert auf einem
                  ausgewogenen Mix aus theoretischen Grundlagen,
                  Verhaltenstraining und Selbstreflexion der Teilnehmer. Für die
                  Trainings konnte jeweils ein überdurchschnittlicher
                  Lerntransfer nachgewiesen werden. Durch die hohe Qualität der
                  Trainings verfügt unser Management-Entwicklungs-Programm über
                  einen ausgezeichneten Ruf im Unternehmen. Wir empfehlen Frau
                  Lehner daher als Trainerin jederzeit gerne weiter.
                </p>
              ),
              author: "Patric Dörr",
              job: "Personalentwickler Stadtwerke München",
              blockquoteActive: true,
              blockquote:
                "Frau Lehner wurde von den Teilnehmern durchweg als ausgesprochen kompetent erlebt.",
            },
            {
              text: (
                <p>
                  Christine Lehner und Heiko Thurner führen seit 2007 für die
                  Audi-Akademie das Outdoor-Seminar „Mein Verhalten im Team“
                  sowie das Seminar „Ich und mein Team – Handlungsspielräume
                  entdecken“ durch.
                  <br />
                  Was ich in der Zusammenarbeit sehr schätze, ist ihre
                  konzeptionelle Stärke sowie gleichzeitig die ziel- und
                  ergebnisorientierte Klarheit in der Durchführung. Von Seiten
                  der Teilnehmer werden vor allem das Eingehen auf die
                  individuellen Fragen und der Praxisbezug hervorgehoben.
                </p>
              ),
              author: "Judith Wagner",
              job: "Audi-Akademie Ingolstadt",
              blockquoteActive: true,
              blockquote:
                "Kreativität, Herzlichkeit, Humor und ständige Verbesserungsideen zeigen, dass sie auch langjährige Standardseminare immer aufs Neue mit viel „Herzblut“ durchführen",
            },
            {
              text: (
                <p>
                  Zwei Jahre begleitete uns das Ammerthaler Institut bei der
                  Organisationsentwicklung einer heilpädagogischen Tagestätte
                  der Lebenshilfe München. „Das Haus der Zukunft bauen“ lautete
                  das Motto, unter das Frau Weihe, Frau Lehner, Frau Frye und
                  Herr Graf die Organisationsentwicklung stellten.
                </p>
              ),
              author:
                "Sabine Refle, Lebenshilfe München Kinder und Jugend GmbH",
              job: "Koordinatorin HPT, SVE und Schule",
              blockquoteActive: true,
              blockquote:
                "Eine beispielhafte Sammlung von Stärken, die das Team um Frau Lehner auszeichnen und wodurch die Organisationsentwicklung zu einem gelungenen und großen Erfolg wurde: Geduld, Respekt, Zusammenarbeit, Mut, Transparenz, Ausdauer, Kreativität, Offenheit, Ideenvielfalt, Mut zum Konflikt.",
            },
          ]}
        />
        <div className="img">
          <div>
            <GatsbyImage alt="test" image={christine_lehner} />
          </div>
        </div>
      </Styled.Details>
      <Profil
        img={sabine}
        imgAlt="test"
        name="Sabine Weihe"
        job="Trainerin, Beraterin, Coach"
        photos={photos}
        p={[
          "Freie und angestellte Tätigkeiten seit 1992 in Groß- und Mittelstandsunternehmen, Konsumgüterbranche, Automobilbranche und Dienstleistungsbranche, Dozentin an der Fachhochschule Rosenheim",
          "Dipl.-Verwaltungswissenschaftlerin, freiberuflich und angestellt tätig als Beraterin, Trainerin und Coach, mit den Schwerpunkten Organisationsentwicklung und Kommunikation",
          <>
            Organisations- und Personalentwicklung, Führungskräfte-/
            <span className="break">Nachwuchskräfte</span>entwicklung,
            Teamentwicklung
          </>,
          "Kredo: „Wichtig für mich ist die systemische Denkweise, also der Einbezug verschiedener Perspektiven, die Bedürfnisse der Teilnehmer*innen und die Ankopplung an deren jeweiligen Praxisbezug im Arbeitskontext.“",
        ]}
        kredo
      />
      <Styled.Details>
        <div className="background"></div>
        <Slide
          data={[
            {
              text: "Sabine Weihe unterrichtet seit 13 Jahren erfolgreich in der berufsbegleitenden Weiterbildung an der Technischen Hochschule Rosenheim Vorlesungen zum Thema Führung, Autorität und Werte. Die Absolventen loben die hohe Professionalität der Dozentin und der Vorlesungsinhalte, den generierten persönlichen und beruflichen Mehrwert und das intensive und offene Arbeiten in einer kleiner motivierten Gruppe. Die Mischung zwischen Theorie und Praxis wird von den Teilnehmenden sehr geschätzt. Wir freuen uns auf die weitere Zusammenarbeit mit dem Ammerthaler Institut.",
              author: "Dipl.-Ing. (FH) Sylvia Posch ",
              job: "Programm-Managerin, Academy for Professionals, Technische Hochschule Rosenheim",
              blockquoteActive: false,
            },
            {
              text: (
                <p>
                  Zwei Jahre begleitete uns das Ammerthaler Institut bei der
                  Organisationsentwicklung einer heilpädagogischen Tagestätte
                  der Lebenshilfe München. „Das Haus der Zukunft bauen“ lautete
                  das Motto, unter das Frau Weihe, Frau Lehner, Frau Frye und
                  Herr Graf die Organisationsentwicklung stellten.
                </p>
              ),
              author:
                "Sabine Refle, Lebenshilfe München Kinder und Jugend GmbH",
              job: "Koordinatorin HPT, SVE und Schule",
              blockquoteActive: true,
              blockquote:
                "Eine beispielhafte Sammlung von Stärken, die das Team um Frau Lehner auszeichnen und wodurch die Organisationsentwicklung zu einem gelungenen und großen Erfolg wurde: Geduld, Respekt, Zusammenarbeit, Mut, Transparenz, Ausdauer, Kreativität, Offenheit, Ideenvielfalt, Mut zum Konflikt.",
            },
            {
              text: (
                <div>
                  <p>
                    Seit 2006 bis heute arbeitet die Hochschule Rosenheim in der
                    berufsbegleitenden Weiterbildung sehr erfolgreich mit Sabine
                    Weihe und Christine Lehner als Dozentinnen zusammen. Wir
                    schätzen die professionelle und sehr angenehme
                    Zusammenarbeit mit dem Ammerthaler Institut. Nachfolgend
                    einige der vielen positiven Beispiele der Beurteilung
                    unserer Teilnehmer:
                  </p>
                  <ul
                    style={{ listStyle: "disc", marginLeft: "var(--gap-13)" }}
                  >
                    <li
                      style={{
                        marginBottom: "var(--gap-13)",
                        marginTop: "var(--gap-13)",
                      }}
                    >
                      „Verbindung wissenschaftlich fundierte Ansätze mit
                      langjähriger praxisorientierter Erfahrung aus der
                      Wirtschaft“ und „sehr erfahrungsorientiertes Lernen mit
                      Spaß…“
                    </li>
                    <li style={{ marginBottom: "var(--gap-13)" }}>
                      „Sehr gut waren Aufbau und Inhalt des Seminars; Theorie
                      praktische Übungen; Teamwork, Eingehen auf situative
                      Fragen und die Gestaltung des Seminars…“
                    </li>
                    <li>
                      „Die exzellente Mischung zwischen Theorie und Praxis
                      (Gruppenarbeiten, Rollenspiele) hat mir besonders gut
                      gefallen...“
                    </li>
                  </ul>
                </div>
              ),
              author: "Prof. Bäßler",
              job: "FH Rosenheim",
              blockquoteActive: false,
            },
            {
              text: "Wir danken Sabine Weihe herzlich für ihre lebendige und praxisnahe Workshop-Reihe, die sie für unsere Nachwuchsführungskräfte konzipiert und durchgeführt hat. Die Teilnehmer waren von den zahlreichen Übungen, Rollenspielen und Reflexionsphasen begeistert und empfanden die Workshops als ausgesprochen erkenntnisreich. Außerdem waren sie fasziniert, wie mühelos und schnell sich Sabine als externe Trainerin auf unsere spezifische Unternehmenskultur eingestellt hat. Das kann auch ich aus der Perspektive der Personalentwicklung bestätigen. Die gemeinsame Zusammenarbeit war unkompliziert, flexibel und überzeugend. Wir haben unsere Nachwuchsführungskräfte gerne und ruhigen Gewissens in Sabines Hände gegeben.",
              author: "Linda Fromm",
              job: "Senior Referentin Personalentwicklung,  CHECK24 - Das Vergleichsportal",
              blockquoteActive: false,
            },
            {
              text: (
                <p>
                  Es gibt die Zeit der Arbeit und die Zeit des Dankes ... und
                  die ist jetzt dran: Für die Vorbereitung und die Moderation
                  unserer Bereichsentwicklung unter besonderen Umständen
                  (mehrmalige Konzeptänderung mit zuletzt einer saftigen Portion
                  an emotionalem Sprengstoff) möchten wir, Herr Hübner und ich
                  uns nochmals ganz recht herzlich bedanken.
                  <br />
                  Sie wären die "Zauberfee aus dem Wunderland", wenn ab sofort
                  alles rund liefe. Rund laufen tut noch nicht alles. Wir haben
                  aber durch Ihre umsichtige Moderation der heiklen
                  Entwichklungsthemen unseres Bereiches einen Einstieg auf den
                  Weg gebracht, der uns praktisch umsetzbar scheint und
                  sicherlich Stabilität bringen wird.
                  <br />
                  Wir werden die vereinbarten Regeln jetzt in Worte fassen und
                  uns daran halten und wie vereinbart den Termin für das Thema
                  "aktives Zuhören" verabreden.
                  <br />
                  Nochmals herzlichen Dank für Ihren Einsatz.
                </p>
              ),
              author: "M. Hüber und B. Ritzenhoff",
              job: "SWM Infrastruktur GmbH",
              blockquoteActive: false,
            },
            {
              text: (
                <p>
                  Im Rahmen eines Benchmark-Projektes haben wir bei einem
                  unserer Kunden Defizite im Bereich Personalführung
                  festgestellt. Als eine wesentliche Maßnahme zur Verbesserung
                  dieser Situation wurde das Thema "Zielvereinbarungen"
                  gemeinsam mit der Unternehmensleitung erkannt und definiert.
                  <br />
                  Im Zuge der Umsetzung dieser Maßnahme haben wir gemeinsam mit
                  Ihnen Workshops geplant und durchgeführt, mit denen die
                  Grundlagen für die Umsetzung der Zielvereinbarungen in dem
                  Kundenunternehmen geschaffen wurden. Dabei haben Sie Ihre
                  Kompetenzen und Erfahrungen als Personaltrainerin in bester
                  Weise einbringen können. Die positiven Reaktionen unserer
                  Kunden bestätigen dies eindrucksvoll.
                  <br />
                  Wir bedanken uns daher bei Ihnen für die gute und für beide
                  Seiten fruchtbare Zusammenarbeit und hoffen gleichzeitig,
                  damit einen Grundstein für weitere gemeinsame Veranstaltungen
                  in dieser Art gelegt zu haben.
                </p>
              ),
              author: "Lothar Ophey",
              job: "InnoTech",
              blockquoteActive: false,
            },
            {
              text: (
                <p>
                  Fr. Weihe führt seit 1998 als freie Trainerin für die CLAAS
                  KGaA mbH in Harsewinkel in regelmäßigen Abständen
                  Präsentations-Workshops im Rahmen unserer
                  Führungskräfteentwicklung durch.
                  <br />
                  Zielgruppe sind sowohl Führungskräfte der 2. Führungsebene als
                  auch Fach- und Führungsnachwuchskräfte, die häufig mit
                  Präsentationsaufgaben konfrontiert werden. Die Schwerpunkte
                  liegen dabei auf die persönliche Wirkung sowie den Einsatz
                  neuer Techniken für die Durchführung von Präsentationen sowohl
                  für den interen Bereich als auch für externe Kunden. Dabei
                  beinhaltet das Seminar kurze Theorie-Inputs und die
                  ausführliche Analyse des Präsentationsverhaltens der
                  Teilnehmer.
                  <br />
                  Fr. Weihe hat die Präsentations-Workshops stets zu unserer
                  vollsten Zufriedenheit und mit ausgezeichneter Rückmeldung von
                  den Teilnehmern durchgeführt.
                  <br />
                  Wir arbeiten mit Fr. Weihe in jeder Weise vertrauensvoll,
                  konstruktiv und sehr erfolgreich zusammen. Fr. Weihe arbeitet
                  seht engagiert und zuverlässig. Hervorzuheben ist ihre
                  selbständige sowie verantwortungsbewusste Arbeitsweise.
                  <br />
                  Wir können den Einsatz von Fr. Weihe uneingeschränkt
                  empfehlen.
                </p>
              ),
              author: "Claudia Joost",
              job: "CLAAS KGaA mbH",
              blockquoteActive: false,
            },
            {
              text: (
                <p>
                  Auch ich möchte mich bei Ihnen für die Durchführung des
                  Seminars in unserem Hause bedanken! Ich habe sehr positive
                  Rückmeldungen von den Teilnehmern erhalten.
                  <br />
                  Ein Teilnehmer hat den Wunsch geäussert, im nächsten Jahr ein
                  Follow up Tag stattfinden zu lassen. Gerne werde ich diesen
                  Gedanken bei der Gestaltung der Folgebroschüre
                  berücksichtigen. Ich freue mich, Sie für weitere Schulungen
                  einplanen zu dürfen.
                </p>
              ),
              author: "Andrea Eckl",
              job: "Corporate Human Resources, Schefenacker AG",
              blockquoteActive: false,
            },
          ]}
        />
        <div className="img">
          <div>
            <GatsbyImage alt="test" image={sabine_weiher} />
          </div>
        </div>
      </Styled.Details>
      <Styled.Cooperation style={{ display: "contents" }}>
        <h3>Unsere Kooperationspartner</h3>
        <Partner
          imgAlt="test"
          img={marianne_frye}
          job={
            <p>
              Systemische Paar- und Familientherapeutin, Systemische
              Supervisorin <br />
              Seit 1989 in eigener Praxis tätig: Paar- und Familientherapie,
              Supervision und Coaching für Non-Profit- und Profitorganisationen
            </p>
          }
          name="Marianne Frye"
          data={[
            {
              text: "Coach und Institutionsberaterin (zertifiziert von der systemischen Gesellschaft), Psychotherapeutin HPG, NLP Master, Dipl. Sozialarbeiterin",
              title: "Ausgebildet",
            },
            {
              text: "Coaching für Führungskräfte, Konfliktmanagement, Organisationsentwicklung",
              title: (
                <>
                  <span>schwerpunkt</span>
                  <span>themen</span>
                </>
              ),
            },
          ]}
        />
        <Partner
          imgAlt="test"
          img={anette_kuhr}
          job={
            <p>
              Trainerin, Beraterin, Coach <br /> Geschäftsführerin/Inhaberin von
              Unicate – Studio für Kommunikation & Design; seit 2013 Coaching
              und Beratung für Mittelstandsunternehmen
            </p>
          }
          name="Annette Kuhr"
          data={[
            {
              text: "Kommunikationsfachwirtin, Systemische Beraterin und Coach, Mediatorin, Psychotherapeutin HPG",
              title: "Ausgebildet",
            },
            {
              text: "Systemisches Coaching und Beratung, Vertriebs- und Führungskräftetraining, Burnout-Prävention, Innovations- und Kreativitäts-Workshops",
              title: "Ziel meiner Arbeit",
            },
          ]}
        />
        <Partner
          job={
            <p>
              Consultant, Coach, Dipl.-Ing. Schwerpunkt Abfallwirtschaft <br />
              Fraunhofer-Institut für Chemische Technologie (1997), Quality
              Management Service AG (1998), VIA Management Consulting GmbH (seit
              1999)
            </p>
          }
          img={thomas_lehner}
          imgAlt={"test"}
          name="Thomas Lehner"
          data={[
            {
              text: "Studium Technischer Umweltschutz an der Technischen Universität Berlin (1990 - 1997), Diplomarbeit: Technik und Wirtschaftlichkeit der Entsorgung von De-Inking-Schlämmen",
              title: "Ausgebildet",
            },
            {
              text: (
                <>
                  Qualitätsmanagement und Total Quality Management (EFQM),
                  Umweltmanagement, Prozessmanagement, Zielvereinbarungsprozess,
                  Kunden- und <span className="break">Mitarbeiter</span>
                  zufriedenheitsmessung, Balanced Scorecard, Benchmarking,
                  Projektmanagement, Risk Management, Wissensmanagement
                </>
              ),
              title: (
                <>
                  <span>schwerpunkt</span>
                  <span>themen</span>
                </>
              ),
            },
          ]}
        />
        <Partner
          job="Coachin, Organisationsentwicklerin, Trainerin"
          img={kaja_novac}
          imgAlt={"test"}
          name="Kaja Novak"
          data={[
            {
              text: "Sprachwissenschaftlerin, Organisationsentwicklerin, Coach, traumaoriented Facilitator",
              title: "Ausgebildet",
            },
            {
              text: "Coaching für tiefe Anliegen,  Coaching rund um Hochsensibilität,  Coaching für emotionale Intelligenz in Teams, Konfliktbearbeitung, Führungskräfteentwicklung.",
              title: (
                <>
                  <span>schwerpunkt</span>
                  <span>themen</span>
                </>
              ),
            },
          ]}
        />
        <Partner
          job={
            <p>
              Trainer, Coach <br /> Lehrbeauftragter Hochschule Bonn-Rhein-Sieg,
              Leitung des SIALL-Instituts (MUC, FFM, CGN) seit 2000
            </p>
          }
          img={achim_stark}
          imgAlt={"test"}
          name="Achim Stark"
          data={[
            {
              text: "Lehrcoach- und NLP-Lehrtrainer (DVNLP), WingWave Lehrtrainer, Reiss-Profile Master, Diplom Informatiker; 14 Jahre Berufserfahrung im Consulting, u.a. CSC, Steria Mummert, SAP",
              title: "Ausgebildet",
            },
            {
              text: "Führungskompetenz, Personale/Soziale Kompetenz: Vertriebsoptimierung, Personal- & Teamentwicklung /-coaching, Konfliktmanagement, Konfliktmoderation, Gesprächsführung, Kommunikation, Stressbewältigung, Präsentation / Persönliches Auftreten",
              title: (
                <>
                  <span>schwerpunkt</span>
                  <span>themen</span>
                </>
              ),
            },
          ]}
        />
        <Partner
          imgAlt="test"
          img={heiko_thurner}
          job="Freiberuflicher Trainer seit 2001"
          name="Heiko Thurner"
          data={[
            {
              text: 'Diplom-Religionspädagoge (FH), Erlebnispädagogische Zusatzqualifikationen "Höhlen" und "Wasser", Systemischer Berater und Trainer',
              title: "Ausgebildet",
            },
            {
              text: "Erlebnispädagogik, Indoor- und Outdoor-Teamtrainings, Kreative Projektbegleitung",
              title: (
                <>
                  <span>schwerpunkt</span>
                  <span>themen</span>
                </>
              ),
            },
          ]}
        />
        <Partner
          imgAlt="test"
          img={ingrid_wild}
          job={
            <p>
              Selbständig mit Wild-Pferd: Ausbildung für Pferd & Reiter <br />
              Lehrerin der Tellington-TTouch -Methode für Pferde
              (TTEAM-Practitioner Level 3, lizensiert von Linda
              Tellington-Jones) Centered Riding Instructorin I, Trainerin B/FN
            </p>
          }
          name="Ingrid Wild"
          data={[
            {
              text: "In der Zusammenarbeit und Ausbildung von Reiter und Pferd, vertraue ich auf die gewaltfreien Grundsätze der Tellington-Touch-Methode. Ich bringe beiden, Mensch und Pferd als individuelle Persönlichkeiten Klarheit, Respekt und Freundlichkeit entgegen.",
              title: (
                <>
                  Meine <span>Schwerpunkt</span>
                  <span>themen</span>
                </>
              ),
            },
            {
              text: "Ziel meiner Arbeit ist es, Sie sicher und selbständig in der Partnerschaft mit dem Pferd zu machen auf der Grundlage von gegenseitigem Verständnis (= Verstehen), Respekt, Wertschätzung und Vertrauen.",
              title: "Ziel meiner Arbeit",
            },
          ]}
        />
        <Partner
          imgAlt="test"
          img={maria_tiefenthaler}
          job={
            <p>
              Training, Beratung und Coaching <br /> Jahrgang 1967
              Freiberufliche Trainerin seit 2004
            </p>
          }
          name="Maria Tiefenthaler"
          data={[
            {
              text: (
                <ul style={{ listStyle: "inside" }}>
                  <li style={{ margin: 0 }}>
                    Umgang mit Komplexität: Souveräner und stressfreier leben
                    und arbeiten
                  </li>

                  <li style={{ margin: 0 }}>
                    Frauenförderung: Mentoring für Frauen in Führung und
                    Management, Coaching für Frauen
                  </li>
                  <li style={{ margin: 0 }}>
                    Talent Development Programme für junge Führungskräfte:
                    Seminar und Coaching
                  </li>
                  <li style={{ margin: 0 }}>
                    Gruppencoaching: Standortbestimmung für junge Führungskräfte
                  </li>
                </ul>
              ),
              title: (
                <>
                  Meine <span>Schwerpunkt</span>
                  <span>themen</span>
                </>
              ),
            },
            {
              text: "Was bedeutet souverän auftreten, sich souverän fühlen und nicht nur so wirken, speziell für Frauen? Ich möchte mit meiner Arbeit mit Frauen dazu beitragen jede Frau zu ihrer ganz persönlichen Souveränität zu begleiten.",
              title: "Ziel meiner Arbeit",
            },
          ]}
        />
      </Styled.Cooperation>
      <Styled.Network>
        <h3>Unser Netzwerk</h3>
        <Network
          imgAlt="test"
          img={logo_hattinger_buero}
          name={"HATTINGER BÜRO GmbH "}
          text={[
            "Addresse: Friedrichstr. 5 45525 Hattingen",
            "Tel.: 023 24 / 56 0 40",
            "Internet: www.hattinger-buero.de",
            "E-Mail: meet-us@hattinger-buero.de",
          ]}
        />
        <Network
          imgAlt="test"
          img={logo_sysTelios_transfer}
          name={"Sytelios Transfer GmbH"}
          text={[
            "Am Tannenberg 17",
            "69483 Wal-Michelbach",
            "Ortsteil Siedelsbrunn",
          ]}
        />
      </Styled.Network>
    </Styled.Container>
  )
}

export default Team

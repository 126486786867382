import React from "react"
import { RowsPhotoAlbum } from "react-photo-album"
import Lightbox from "yet-another-react-lightbox"

import "react-photo-album/rows.css"
import "yet-another-react-lightbox/styles.css"

const Photos = ({ photos }: any) => {
  const [index, setIndex] = React.useState(-1)

  return (
    <div style={{ marginBottom: "35px", gridColumn: "1 / -1" }}>
      <RowsPhotoAlbum
        photos={photos}
        rowConstraints={{ singleRowMaxHeight: 100 }}
        onClick={({ index: current }) => setIndex(current)}
      />
      <Lightbox
        index={index}
        slides={photos}
        open={index >= 0}
        close={() => setIndex(-1)}
      />
    </div>
  )
}

export default Photos
